.modal-container {
    width:100%;
    height:100%;
    position:fixed;
    top:0;
    left:0;
    z-index:9999;
    visibility:hidden;
    transition: visibility 0.3s ease;
}

.modal {
  width:100%;
  height:100%;
  position:relative;
  color:rgb(156 163 175);
  overflow-y: auto;
  bottom: -100%;
  background-color: rgb(10, 15, 26);
  transition: bottom 0.3s ease;
}

.modal h2 {
    font-weight: bold;
    font-size: 40px;
    color:white;
    font-family: 'League Spartan';
    text-align: center;
  }

.modal h3 {
  margin-bottom: 15px;
}

.modal h4 {
  padding: 25px;
}

.modal-header {
  position: fixed;
  text-align: left;
  margin: 0;
  border-bottom: 1px solid gray;
  background-color: rgb(31 41 55);
  width: 100%;
  z-index: 20;
}

.modal-main-content {
  box-shadow: 12px 0 15px -4px rgba(35, 54, 78, 0.8), -12px 0 8px -4px rgba(35, 54, 78, 0.8);
  width: 75%;
  text-align:center;
  margin: auto;
  background-color: rgb(17 24 39);
}

.modal-content {
  width: 75%;
  margin:auto;
  text-align: left;
}

.modal-image {
  width: 100%;
  height: 300px;
  margin-top: 75px;
  padding: 0px !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.close-button {
    position: absolute;
    right: 20px;
    top: 18px;
    border: 2px solid white;
    border-radius: 10px;
    padding-right: 3px;
    transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: rgba(158, 43, 43, 0.8);
}

.line {
  width: 15%;
  margin: 10px auto 40px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.473);
}

@media (max-width: 767px) {
  .modal-main-content {
    width: 100%;
  }
}
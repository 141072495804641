#projects {
    background-color: rgb(20, 28, 44);
}

.info-overlay {
    background-color: rgba(17, 24, 39, 0.9);
    transition: opacity 0.3s ease;
}

.project-box:hover {
    cursor:pointer;
}

.project-box {
    height:250px;
}

.project-box img {
    padding:1px;
}

.project-box:hover img {
    filter: blur(1px);
}

.btn-category {
    border: 1px solid white;
    padding: 10px 20px;
    margin: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
}

.btn-category:hover {
    background-color: green;
}
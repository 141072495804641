.iframe-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
    background-color: rgb(11, 16, 27);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 5%;
    border-radius: 5px;
    margin: 40px 0px;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.project-section p {
    font-size: 1.2em;
    margin-top: 15px;
}

.project-section h1 {
    font-size: 1.7em;
    font-weight: bold;
    color: white;
    border-left: 3px solid #1d8d87;
    padding-left: 15px;
    margin-top: 50px;
}

.project-section h2 {
    font-family:sans-serif;
    font-size: 1.4em;
    font-weight: bold;
    border-left: 2px solid #9236c7;
    padding-left: 15px;
    margin-top: 30px;
    text-align: left;
}

.project-section h3 {
  color: white;
  font-family:sans-serif;
  font-size: 1.2em;
  font-weight: bold;
  margin-top: 20px;
  text-align: left;
}

blockquote{
    font-size: 1em;
    margin:50px auto;
    font-family:Open Sans;
    font-style:italic;
    padding:1.2em 30px 1.2em 75px;
    border-left:8px solid #78C0A8 ;
    line-height:1.6;
    position: relative;
  }
  
  blockquote::before{
    font-family:Arial;
    content: "\201C";
    color:#78C0A8;
    font-size:4em;
    position: absolute;
    left: 10px;
    top:-10px;
  }
  
  blockquote::after{
    content: '';
  }

  .project-section img {
    border-radius: 5px;
    width: 75%;
    margin: 25px auto;
  }

  .project-link-section {
    padding-top: 40px;
    text-align:center;
  }

a.link-button, a.file-button {
    padding: 10px;
    border-radius: 5px;
    color: white;
    transition: background-color 0.3s ease;
}

a.link-button {
  background-color: green;
}

a.link-button:hover {
  background-color: darkgreen;
}

a.file-button {
  background-color: #1d8d87;
}

a.file-button:hover {
  background-color: #13635f
}

ul {
  font-size: 1.1em;
  margin-top: 30px;
  list-style-type: circle;
  list-style-position: inside;
}

ul li {
  margin-bottom: 5px;
}

.project-credits {
  text-align:center;
}

.project-credits h1, .project-credits h2 {
  text-align:center;
  border: none;
  padding: 0px;
}

.project-link-section img {
  filter: brightness(0) invert(1);
}
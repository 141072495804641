#landing {
    background-color: rgb(13, 18, 29);
}

#landing .about-btn {
    transition: background-color 0.3s ease, color 0.3s ease;
    box-shadow: 2px 2px  rgba(253, 251, 251, 0.5);
}

#landing .video-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    visibility: hidden;
}

#landing .video-wrap video {
    min-width: 100%;
    min-height: 100%;
}

#landing .overlay {
    z-index: 1;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgb(13, 18, 29);
    position: absolute;
    opacity: .7;
    visibility: hidden;
}

#landing .landing-text {
    z-index: 2;
}

@media (min-width: 1024px){
    #landing .video-wrap {
        visibility: visible;
    }

    #landing .overlay {
        visibility: visible;
    }
}

.arrow {
    z-index: 2;
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.arrow span {
    display: block;
    width: 20px;
    height: 20px;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
    transform: rotate(45deg);
    margin: -10px;
    animation: scroll 2s infinite;
}

.arrow span:nth-child(2) {
    animation-delay: -.2s;
}

.arrow span:nth-child(3) {
    animation-delay: -.4s;
}

@keyframes scroll{
    0% {
        opacity:0;
        transform: rotate(45deg) translate(-10px, -10px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(45deg) translate(10px, 10px);
    }
}
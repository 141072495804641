.fullnavbar {
    transition: left 0.3s ease;
}

.navlink {
    display:inline-flex;
    align-items: center;
    padding:10px;
    transition: background-color 0.3s, color 0.3s;
}

.navlink:hover {
    background-color: rgb(55 65 81);
    color: white;
    cursor: pointer;
    border-radius: 0.25rem;
    font-size: 1rem;
    line-height: 1.5rem; 
    transition: background-color 0.3s, color 0.3s;
}

.namelink {
    transition: opacity 0.3s ease;
}

.namelink:hover {
    opacity: 0.8;
}

.namelink > .logo {
    -webkit-transition: filter 0.3s ease;
    -o-transition: filter 0.3s ease;
    transition: filter 0.3s ease;
}

.namelink:hover > .logo {
    -webkit-filter: invert(0.33) sepia(1) saturate(16.6) hue-rotate(126deg) brightness(0.97);
    filter: invert(0.33) sepia(1) saturate(16.6) hue-rotate(126deg) brightness(0.97);
}

.darken {
    width:100%;
    height:100%;
    visibility: hidden;
    position: fixed;
    opacity:0;
    background-color:black;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

@media (max-width: 767px){
    header {
        position:sticky;
        margin-bottom:20px;
        height:70px;
    }

    .fullnavbar {
        display: inline;
        background-color: #101010;
        height: 100vh;
        width: 50vw;
        margin-top: 70px;
        left:0;
        top:0;
        position: fixed;
        z-index: 20;
        text-align:left;
    }

    .navlink {
        width: 100%;
        padding: 10px;
        border-bottom: 2px solid rgba(128, 128, 128, 0.5);
        margin-right:0px;
    }

    .mainnav {
        text-align: left;
    }

    .namelink {
        display:none;
    }

    .contact-icon {
        display:none;
    }

    .darken {
        margin-top: 70px;
        top: 0;
    }
}
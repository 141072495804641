.about-btn {
    transition: background-color 0.3s ease, color 0.3s ease;
}

#cv-btn {
    background-color: #1d8d87
}

#cv-btn:hover {
    background-color: #13635f
}
.hamburger{
    width: 2rem;
    height: 2rem;
    display: none;
    justify-content: space-around;
    flex-flow: column nowrap;
    z-index: 10;
}
.burger{
    width: 2rem;
    height: 0.25rem;
    border-radius: 10px;
    background-color: white;
    transform-origin: 1px;
    transition: all 0.3s linear;
    margin-top:3px;
    margin-left: 20px;
}

.hamburger:hover {
    cursor:pointer;
}

@media (max-width: 767px){

    .hamburger{
        display:inline;
        padding-top: 10px;
        margin-left: 10px;
        z-index: 20;
    }
}

